import React, { type ReactNode } from 'react';

interface RegularPageProps {
  children: ReactNode;
}

const RegularPage: React.FC<RegularPageProps> = ({ children }: RegularPageProps) => {
  return (
    <div className='container-lg pt-2'>
      <div className='row'>
        <div className='col mb-4'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RegularPage;
