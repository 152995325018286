import classNames from 'classnames';
import React from 'react';

interface PageHeaderProps {
  header: string;
  muted?: string | undefined;
  className?: string | undefined;
}

const PageHeader: React.FC<PageHeaderProps> = ({ ...props }: PageHeaderProps) => {
  return (
    <h1
      className={classNames('fw-light', props.className ? props.className : 'pb-3')}
    >
      {props.header}
      {props.muted && (
        <small>
          <small className='text-muted ms-1'>
            <em className='text-break'>
              ({props.muted})
            </em>
          </small>
        </small>
      )}
    </h1>
  );
};

export default PageHeader;
