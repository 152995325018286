import React from 'react';
import PageHeader from './PageHeader';
import PageMetadata from './PageMetadata';

interface PageHeaderWithMetadataProps {
  header: string;
  muted?: string | undefined;
  className?: string | undefined;
  headTitle?: string | undefined;
  headMetaDescription?: string | undefined;
}

const PageHeaderWithMetadata: React.FC<PageHeaderWithMetadataProps> = ({ ...props }: PageHeaderWithMetadataProps) => {
  return (
    <>
      <PageMetadata
        title={props.headTitle ?? props.header}
        metaDescription={props.headMetaDescription ?? props.headTitle ?? props.header}
      />
      <PageHeader
        header={props.header}
        muted={props.muted}
        className={props.className}
      />
    </>
  );
};

export type {
  PageHeaderWithMetadataProps
};

export default PageHeaderWithMetadata;
