import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setShowPageNotFound404 } from '../../../store/modules/app/commonReducer';
import type { ICommonAwareState } from '../../../store/modules/app/models/ICommonAwareState';
import RegularPage from '../../layout/sections/RegularPage';
import PageHeaderWithMetadata from '../../shared/elements/PageHeaderWithMetadata';

const ErrorNotFoundPage: React.FC = () => {

  const location = useLocation();

  const [displayedForLocationPathname] = useState(location.pathname);

  const showPageNotFound404 = useSelector((state: ICommonAwareState) => state.common.showPageNotFound404);

  const dispatch = useDispatch();

  useEffect(() => {
    if (displayedForLocationPathname != location.pathname) {
      if (showPageNotFound404) {
        dispatch(setShowPageNotFound404(false));
      }
    }
  }, [dispatch, displayedForLocationPathname, location.pathname, showPageNotFound404]);

  useEffect(() => () => {
    if (showPageNotFound404) {
      dispatch(setShowPageNotFound404(false));
    }
  }, [dispatch, showPageNotFound404]);

  return (
    <RegularPage>
      <PageHeaderWithMetadata header='Strona nie istnieje' />
    </RegularPage>
  );
};

export default ErrorNotFoundPage;
